<template>
    <div>
      <h3 style="display: inline-flex">当前位置：{{this.$store.state.example_module.moduleName}}</h3>
      <el-menu
        class="site-navbar__menu site-navbar__menu--right"
        mode="horizontal"
        >

        <el-menu-item index="1">
            <a @click="$router.push({ name: 'home' })" target="_blank">首页</a>
        </el-menu-item>
        <el-menu-item index="2">
            <a @click="$router.push({ name: 'example1Page1' })" target="_blank">界面1</a>
        </el-menu-item>
        <el-menu-item index="3">
            <a @click="$router.push({ name: 'example1Page2' })" target="_blank">界面2</a>
        </el-menu-item>
        <el-menu-item class="site-navbar__avatar" index="4">
            <el-dropdown :show-timeout="0" placement="bottom">
                <span class="el-dropdown-link">
                <img src="~@/assets/img/touxiang.jpg" :alt="userName">{{ userName }}
                </span>
                <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="updatePasswordHandle()">修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="logoutHandle()">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
          </el-menu-item>
      </el-menu>
      <update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password>
    </div>
</template>

<script>
// import { clearLoginInfo } from '@/utils'
import UpdatePassword from '../main-navbar-update-password'
  export default {
    data () {
      return {
        updatePassowrdVisible: false
      }
    },
    components: {
      UpdatePassword
    },
    computed: {
      userName: {
        get () { return this.$store.state.module.moduleName }
      }
    },
    methods: {
      // 修改密码
      updatePasswordHandle () {
        this.updatePassowrdVisible = true
        this.$nextTick(() => {
          this.$refs.updatePassowrd.init()
        })
      },
      // 退出
      logoutHandle () {
        this.$confirm(`确定进行[退出]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          // 先这样，后面把这段注了
          // console.log(this.$store.state.common.isReLogin)
          // if(this.$store.state.common.isReLogin == true){
          //   window.location.reload()
          //   this.$store.state.common.isReLogin = false
          // }
          this.$router.replace({ name: 'login' })

          // this.$http({
          //   url: this.$http.adornUrl('/sys/logout'),
          //   method: 'post',
          //   data: this.$http.adornData()
          // }).then(({data}) => {
          //   if (data && data.code === 0) {
          //     clearLoginInfo()
          //     if(this.$store.state.common.isReLogin == true){
          //       window.location.reload()
          //       this.$store.state.common.isReLogin = false
          //     }
          //     this.$router.replace({ name: 'login' })
          //   }
          // })
        }).catch(() => {})
      }
    }
  }
</script>
